<template>
  <div class="wh-space-bottom">
    <b-card>
      <edit-field-header
        :title="title"
        @routerBack="$router.back()"
      />

      <edit-field
        :is-edit="true"
        :fields="fields"
        :row-data="row_data"
        post-api="/kiosk/WeHealth/UpdateKioskPeripheralMode"
        finish-router="kioskPeripheralMode"
        permission=""
      >
        <template v-slot:fixedPeripherals="scope">
          <slot-fixed-peripherals
            ref="slot-fixed-peripherals"
            :root-item="scope.rootItem"
            :items="row_data['devices']"
            :modelList="modelList"
            :id="id"
            @updateValues="updateFixedPeripherals"
          />
        </template>
      </edit-field>
    </b-card>
  </div>
</template>

<script>
import fields, { reloadFieldData } from './fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import SlotFixedPeripherals from './FieldModelFixedPeripherals'
import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils'
import { flattenObject, expandObject } from '@/libs/ez-utils'
import _ from 'lodash'

export default {
  name: 'Edit',
  components: {
    EditFieldHeader,
    EditField,
    fields,
    SlotFixedPeripherals,
  },

  data() {
    return {
      title: common.getMenuName('kioskPeripheralModel') + ' - ' + common.getI18n('common.edit'),
      fields,
      modelList: [],
      row_data: {
        fixedPurposes: [],
        devices: [],
      },
    }
  },

  computed: {
    id() {
      const id = this.$router.currentRoute.params.id
      return id ? common.decrypt(id) : undefined
    }
  },

  watch: {
    row_data: {
      handler(_value, _oldValues) {
        this.updatePurposes()
      },
      deep: true,
    },
  },

  created() {
    reloadFieldData()
  },

  mounted() {
    if (this.id === undefined) {
      this.$router.replace({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    // common.apiGetData('/kiosk/WeHealth/GetKioskPeripheralMode?kioskToken=' + this.id)
    common.apiGetData('/kioskmanage/GetKioskDeviceDetail?kioskToken=' + this.id)
      .then(res => {
        this.row_data = { ...this.row_data, ...res.data.detail }
        this.updatePurposes()
      })
    common.apiGetData(common.getTableUrl(
      // '/kiosk/WeHealth/GetKioskPeripheralModelList',
      '/kioskmanage/GetDeviceModels',
      undefined, {}, {
        pageIndex: 0,
        pageSize: 999
      }))
      .then(({ data }) => {
        this.modelList = data.models
          .filter(m => !!m.bleName) // 篩選 “包含 bleName 的”
          .filter(m => m.purposes.some(p => kioskUtils.fixablePurposes.includes(p))) // 篩選 “可固定的”
        this.updatePurposes()
      })

  },

  methods: {
    updatePurposes() {
      if (this.row_data.devices.length < 1) return
      if (this.modelList.length < 1) return

      const purposes = this.row_data.devices
        .filter(p => p.bleName)
        .map(p => this.modelList.find(m => m.bleName === p.bleName)?.purposes || [])
        .flat();

      if (!_.isEqual(this.row_data.fixedPurposes, purposes)) {
        this.row_data.fixedPurposes = purposes
      }
    },
    updateFixedPeripherals(value) {
      this.row_data.devices = value
    }
  },
}
</script>

<style scoped>

</style>
